import React from "react";

import PageWrapper from "../components/PageWrapper";
import Content5 from "../sections/kit-digital/Content5";
import MoreInfo from "../components/MoreInfo/MoreInfo";
import Pricing from "../sections/kit-digital/Pricing";
import Company from "../sections/kit-digital/Company";
import Content3 from "../sections/kit-digital/Content3";
import Feature from "../sections/index/Feature";
import Progress from "../sections/kit-digital/Progress";
import Content2 from "../sections/kit-digital/Content2";

const KitDigital = () => {
  return (
    <>
      <PageWrapper>
        <Content5 className="pt-34 pt-lg-48 pb-10 pb-sm-5 pb-lg-14" />
        <Company className="pt-12 pb-9" />
        <MoreInfo className="bg-buttercup py-10 mt-10" />
        <Content2 className="pt-11 pt-lg-34 pb-10 pb-sm-5 pb-lg-14" />
        <Content3 className="pt-11 pt-lg-24 pb-10 pb-sm-5 pb-lg-14" />
        <Feature className="pt-4 pt-lg-18 pb-15 pb-lg-25 border-bottom border-default-color-3" />
        <Progress className="pb-lg-21" />
        <Pricing className="pt-11 pb-8 pb-lg-33" />
        <MoreInfo className="bg-buttercup py-10" />
      </PageWrapper>
    </>
  );
};
export default KitDigital;
