import React, { useState } from "react";
import { useFormInput } from "../../hooks/useFormInput";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Loading from "../../components/Loading";

const Content3 = ({ className, ...rest }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const email = useFormInput("");
  const firstName = useFormInput("");
  const lastName = useFormInput("");
  const phone = useFormInput("");
  const consent = useFormInput(false);

  const emailVal = email.value;
  const firstNameVal = firstName.value;
  const lastNameVal = lastName.value;
  const phoneVal = phone.value;
  const consentVal = consent.value;

  const contentWidgetData = [
    {
      icon: "fa-star",
      title: "Programa de digitalización para empresas",
      text:
        "Es un programa de ayudas que tiene como objetivo ayudar a autónomos y PYMES a alcanzar la madurez digital.",
    },
    {
      icon: "fa-building",
      title: "PYMES de cualquier sector",
      text:
        "De entre 10 y 49 empleados que facturen menos de 10 millones de euros.",
    },
    {
      icon: "fa-copy",
      title: "Déjalo en nuestras manos",
      text: "Olvídate de los trámites. Nos encargamos de la burocracia por ti.",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault(); //--> prevent the page from reloading on form submit
    setLoading(true);
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return;
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("homepage");

    // Prepare the data for the server, specifically body-parser
    const data = JSON.stringify({
      email: emailVal,
      firstName: firstNameVal,
      lastName: lastNameVal,
      phone: phoneVal,
      token: result,
      kitDigital: true,
    });
    // POST request to your server
    fetch("https://app.timeview.io/api/companies/requestinfo", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(
            "Ha ocurrido un error, intentálo más tarde o contacta con nosotros a través de hola@timeview.io"
          );
        }

        return res.json();
      })
      .then((data) => {
        setLoading(false);
        setError("");
        setFeedback(
          "¡Hemos recibido tu solicitud! Pronto te informaremos de todo lo que necesitas para disfrutar de tu Bono Digital."
        );
      })
      .catch((error) => {
        setLoading(false);
        setFeedback("");
        setError(error?.message);
      });
  };

  return (
    <>
      <div id="form" className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Left */}
            <div
              className="col-xl-6 col-lg-5 col-md-6 col-xs-8 order-2 order-md-1"
              data-aos="fade-right"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <h2 className="font-size-11 mt-9">
                Programa de digitalización para empresas
              </h2>
              <ul className="list-unstyled mt-9 mt-lg-14">
                {contentWidgetData.map(({ icon, title, text }, index) => {
                  return (
                    <li className="media align-items-center mb-12" key={index}>
                      <div className="border square-83 rounded-10 mr-9">
                        <span className="text-blue-1 font-size-9">
                          <i className={`fa ${icon}`} />
                        </span>
                      </div>
                      <div className="content">
                        <h5>{title}</h5>
                        <p className="font-size-5 line-height-28 mb-0">
                          {text}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* Left End */}
            {/* Right */}
            <div
              className="col-xl-4 offset-xl-2 col-lg-7 col-md-6 col-xs-8 order-1 order-md-2"
              data-aos="fade-left"
              data-aos-delay={500}
              data-aos-once="true"
            >
              {loading && <Loading background="rgb(247 248 250 / 48%)" />}
              <div className="pt-23 pt-md-17 pt-lg-18 pb-md-4 pb-lg-10 max-w-413 mx-auto">
                <div
                  className="mb-10"
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-delay={500}
                >
                  <h6 className="font-size-5 text-blue mb-5 mb-lg-9 font-weight-normal">
                    Pide más información para usar tu Bono Digital
                  </h6>
                  <h2 className="mb-1 font-size-10 letter-spacing-n83">
                    ¡Infórmate!
                  </h2>
                  <p className="text-bali-gray font-size-7 mb-0">
                    Consigue una subvención del 100%
                  </p>
                </div>
                <form
                  className="register-form"
                  action="/"
                  data-aos="fade-up"
                  data-aos-duration={800}
                  data-aos-delay={500}
                  onSubmit={handleSubmit}
                >
                  {/* Email */}
                  <div className="form-group mb-6 position-relative">
                    <input
                      value={emailVal}
                      onChange={email.onChange}
                      type="email"
                      name="email"
                      className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5"
                      placeholder="Email*"
                      id="email"
                      required
                    />
                  </div>
                  {/* First Name & Last Name */}
                  <div className="form-group form-group-inline mb-6 position-relative">
                    <input
                      value={firstNameVal}
                      onChange={firstName.onChange}
                      type="text"
                      name="firstName"
                      className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 mr-6"
                      placeholder="Nombre*"
                      id="firstName"
                      required
                    />
                    <input
                      value={lastNameVal}
                      onChange={lastName.onChange}
                      type="text"
                      name="lastName"
                      className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5"
                      placeholder="Apellidos*"
                      id="lastName"
                      required
                    />
                  </div>
                  {/* Phone & employees */}
                  <div className="form-group mb-6 position-relative">
                    <input
                      value={phoneVal}
                      onChange={phone.onChange}
                      type="tel"
                      name="phone"
                      className="form-control form-control-md bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 mr-6"
                      placeholder="Teléfono*"
                      id="phone"
                      required
                    />
                  </div>
                  {/* Terms */}
                  <div className="form-group mb-6 position-relative text-bali-gray font-size-3">
                    <input
                      checked={consentVal}
                      onChange={consent.onChange}
                      type="checkbox"
                      name="consent"
                      className="bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray font-size-5 mr-2"
                      id="consent"
                      required
                    />
                    He leído y consiento al contenido de la{" "}
                    <a href="/legal">Información Legal</a> y de la{" "}
                    <a href="/privacy">Política de Privacidad</a>, consintiendo
                    expresamente al tratamiento de mis datos personales conforme
                    a su contenido.
                  </div>
                  <div className="button">
                    <button
                      type="submit"
                      className="btn btn-blue-3 w-100 rounded-4"
                      disabled={!consentVal}
                    >
                      Solicitar información
                    </button>
                    <p className="mt-5 text-bali-gray font-size-3">
                      Esta página está protegida por el reCAPTCHA. Se aplica la{" "}
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Política de Privacidad
                      </a>{" "}
                      de Google y los{" "}
                      <a
                        href="https://policies.google.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Términos del Servicio
                      </a>
                      .
                    </p>
                    {feedback && (
                      <h6 className="font-size-5 text-green mb-5 mb-lg-9 mt-5 font-weight-normal">
                        {feedback}
                      </h6>
                    )}
                    {error && (
                      <h6 className="font-size-5 text-red mb-5 mb-lg-9 mt-5 font-weight-normal">
                        {error}
                      </h6>
                    )}
                  </div>
                </form>
              </div>
            </div>
            {/* Right */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content3;
