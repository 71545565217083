import React from "react";

const Content2 = ({ className, ...rest }) => {
  const contentWidgetData = [
    {
      icon: "fa-check",
      title: "500€",
      text: "1 a 3 empleados",
    },
    {
      icon: "fa-check",
      title: "2.000€",
      text: "4 a 9 empleados",
    },
    {
      icon: "fa-check",
      title: "6.000€",
      text: "10 a 50 empleados",
    },
  ];

  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Content2 Section */}
            <div
              className="col-xl-6 col-lg-7 col-md-8 col-sm-11"
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <div className="ml-lg-10 ml-xl-12 mt-lg-n20 pr-lg-10 pr-xl-10">
                <h6 className="font-size-5 text-blue mb-5 mb-lg-9 font-weight-normal">
                  SOBRE EL KIT DIGITAL
                </h6>
                <h3 className="font-size-11 mb-5 mb-lg-8 font-weight-medium pr-sm-20 pr-md-16 pr-lg-0">
                  ¿Qué es el bono del Kit Digital?
                </h3>
                <p className="font-size-6">
                  El Kit Digital para pymes es una iniciativa planteada por el
                  Gobierno de España que cuenta con una dotación de{" "}
                  <strong className="text-blue">3.067 millones de euros</strong>{" "}
                  y está englobada en la agenda España Digital 2025. La
                  financiación proviene de los fondos{" "}
                  <strong className="text-blue">
                    Next Generation de la Unión Europea
                  </strong>{" "}
                  y se destina a la compra de soluciones digitales.
                </p>
                <p className="font-size-6">
                  En su primera fase, la ayuda da acceso a un bono digital a
                  pequeñas empresas. Su cuantía alcanzará los 12.000€ en función
                  a la categoría de la solución digital elegida y el tamaño de
                  la empresa.{" "}
                  <strong className="text-blue">
                    La categoría correspondiente a Timeview es Gestión de
                    Procesos
                  </strong>{" "}
                  cuya máxima subvención del bono digital es de 6.000€.
                </p>
              </div>
            </div>
            {/* Image Section */}
            <div
              className="col-xl-4 offset-xl-2 col-lg-5 col-md-7 col-xs-10"
              data-aos="fade-up"
              data-aos-duration={800}
              data-aos-delay={500}
            >
              <div>
                <h6 className="font-size-5 text-blue mb-5 mb-lg-9 font-weight-normal">
                  Ayuda máxima en la categoría de Gestión de Procesos
                </h6>
                <h2 className="font-size-11">6.000€</h2>
                <ul className="list-unstyled mt-9 mt-lg-14">
                  {contentWidgetData.map(({ icon, title, text }, index) => {
                    return (
                      <li
                        className="media align-items-center mb-12"
                        key={index}
                      >
                        <div className="border square-83 rounded-10 mr-9">
                          <span className="text-blue-1 font-size-9">
                            <i className={`fa ${icon}`} />
                          </span>
                        </div>
                        <div className="content">
                          <h5>{title}</h5>
                          <p className="font-size-5 line-height-28 mb-0">
                            {text}
                          </p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content2;
