import React, { useState } from "react";

import sectionShape from "../../assets/image/svg/footer-shape.svg";

const Pricing = ({ className, ...rest }) => {
  const [plan] = useState("mensual");

  // const pricings
  const PricingData = [
    {
      title: "Core",
      color: "#FFC24D",
      list: [
        "Ficha empleado",
        "Expediente del empleado",
        "Gestión acreditaciones",
        "Gestión documental",
        "Gestión incidencias",
        "Firma digital",
        "Notificaciones",
        "Teletrabajo",
        "Copias de seguridad y mantenimiento",
      ],
      price: plan === "mensual" ? "0 €" : "0 €",
    },
    {
      title: "Control",
      color: "#4DD1FF",
      list: [
        <b style={{ color: "#FFC24D" }}>Timeview Core</b>,
        "Control de fichajes",
        "Planificación manual",
        "Turnos y patrones",
        "Ausencias y vacaciones",
        "Horas extras",
        "Resumen de equipo",
        "Permutas",
        "Centros de trabajo",
        "Gestión de disp. de fichaje",
      ],
      price: plan === "mensual" ? "0 €" : "0 €",
    },
    {
      title: "Experience",
      color: "#1bea7a",
      list: [
        <b style={{ color: "#FFC24D" }}>Timeview Core</b>,
        <b style={{ color: "#4DD1FF" }}>Timeview Control</b>,
        "Gestión de proyectos",
        "Gestión de viajes",
        "Moneda virtual y material del empleado",
      ],
      price: plan === "mensual" ? "0 €" : "0 €",
    },
  ];

  return (
    <div className={`position-relative ${className}`} {...rest}>
      <div className="container">
        {/* Section Title */}
        <div className="mb-13 mb-lg-13">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-10">
              <div
                className="mb-10 mb-lg-0 text-center text-lg-left"
                data-aos="fade-right"
                data-aos-delay={500}
              >
                <h2 className="font-size-10 pr-md-10 pr-xl-0 mb-0">
                  Elige el plan que mejor se adapte a ti
                </h2>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 text-center text-md-right"></div>
          </div>
        </div>
        {/* End Section Title */}

        {/* Table Main Body */}
        <div className="table-body">
          <div className="row justify-content-center">
            {PricingData.map(({ title, list, price, color }, index) => {
              return (
                <div className="col-lg-12 col-sm-6" key={index}>
                  <div
                    className="border-top pt-10 pb-8"
                    data-aos="fade-up"
                    data-aos-delay={500}
                  >
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mb-5 mb-lg-0">
                          <h3 className="font-size-9" style={{ color }}>
                            {title}
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="">
                          <ul className="list-unstyled font-size-6">
                            {list.map((item, index) => (
                              <li
                                className="heading-default-color mb-4"
                                key={index}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="pr-lg-15 pr-xl-10 mb-5 mb-lg-0">
                          <h2 className="mb-0 font-size-11 font-weight-medium">
                            {price}
                          </h2>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-5 mb-lg-0 mt-4 text-left text-lg-right">
                          <a
                            className="btn btn-outline-gray-1 btn-2 border-width-2 rounded-5 gr-hover-bg-blue-3 heading-default-color"
                            href="mailto:hola@timeview.io"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contacta con nosotros
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Table Main Body */}
      </div>
      <div className="pricing-bottom-shape d-none d-sm-block">
        <img
          src={sectionShape}
          alt=""
          data-aos="fade-left"
          data-aos-delay={500}
        />
      </div>
    </div>
  );
};

export default Pricing;
