import React from "react";
import { Link } from "gatsby";

import imgC from "../../assets/image/inner-page/png/planning.png";
import imgDot from "../../assets/image/inner-page/png/dot-bg.png";

const Content = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Image Section */}
            <div className="col-xl-6 col-lg-5 col-md-7 col-xs-10">
              <div
                className="l1-content-image-group-1 mb-10 mb-lg-0"
                data-aos="fade-right"
                data-aos-duration={500}
                data-aos-once="true"
              >
                <img className="w-100" src={imgC} alt="" />
                <div className="abs-img-1">
                  <img className="w-100" src={imgDot} alt="" />
                </div>
                {/* l1-content-card-1 Image Section */}
              </div>
            </div>
            {/* Content Section */}
            <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
              <div
                className="ml-lg-10 ml-xl-12 mt-lg-n20 pr-lg-10 pr-xl-10"
                data-aos="fade-left"
                data-aos-duration={800}
                data-aos-delay={300}
                data-aos-once="true"
              >
                <h6 className="kit-label mb-5 mb-lg-9">
                  Agente Digitalizador Oficial
                </h6>
                <h3 className="font-size-11 mb-5 mb-lg-8 font-weight-medium pr-sm-20 pr-md-16 pr-lg-0">
                  Gestión de los RRHH digital y gratis
                </h3>
                <p className="font-size-6">
                  Somos agentes digitalizadores oficiales del Kit Digital. Te
                  ayudamos con la gestión de tus RRHH en una sola herramienta
                  sin coste para ti.
                </p>
                <p className="font-size-6 mb-0">
                  En Timeview te ayudamos sin coste alguno a realizar todos los
                  trámites para conseguir tu Bono Digital.
                </p>
                <div
                  className="mt-8 mt-lg-16"
                  data-aos="fade-left"
                  data-aos-duration={800}
                  data-aos-delay={500}
                  data-aos-once="true"
                >
                  <Link to="#form" className="btn btn-blue-3 rounded-5">
                    ¡Lo quiero!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
