import React from "react";

import img1 from "../../assets/image/inner-page/company-logo/kitgob.png";

const SingleBrand = ({ className, imageLight, imageDark, ...rest }) => {
  return (
    <div
      className={`single-brand-logo mx-5 my-6${
        className ? " " + className : ""
      }`}
    >
      <img
        className="light-version-logo-gob"
        src={imageLight}
        alt=""
        {...rest}
      />
    </div>
  );
};
const Company = ({ className, ...rest }) => {
  const images = [
    {
      imageDark: img1,
      imageLight: img1,
    },
  ];

  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="brand-logo-small d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap">
          {images.map(({ imageDark, imageLight, delay, duration }, index) => {
            if (delay && duration) {
              return (
                <SingleBrand
                  imageDark={imageLight}
                  imageLight={imageDark}
                  data-aos="fade-left"
                  data-aos-once="true"
                  data-aos-delay={delay}
                  data-aos-duration={duration}
                  key={index}
                />
              );
            } else if (delay) {
              return (
                <SingleBrand
                  imageDark={imageLight}
                  imageLight={imageDark}
                  data-aos="fade-in"
                  data-aos-once="true"
                  data-aos-delay={delay}
                  key={index}
                />
              );
            } else if (duration) {
              return (
                <SingleBrand
                  imageDark={imageLight}
                  imageLight={imageDark}
                  data-aos="fade-in"
                  data-aos-once="true"
                  data-aos-duration={duration}
                  key={index}
                />
              );
            }
            return (
              <SingleBrand
                imageDark={imageLight}
                imageLight={imageDark}
                data-aos="fade-in"
                data-aos-once="true"
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Company;
